import React from "react"

const style = {
  fontSize: "200px",
  color: "#ffffff",
  margin: 0,
  padding: 0,
  height: "100vh",
  textAlign: "center",
  verticalAlign: "middle",
  backgroundColor: "rgb(34,193,195)",
  background: "linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}

export default function Home() {
  return <div style={style}>o7</div>
}
